import React from 'react'
import Article, { VARIANT_TEXT_INTENDED } from '../modules/Article.js'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import SEO from '../components/SEO'

const pageConfig = {
  title: `Impressum - ${config.siteTitle}`,
  slug: `impressum`,
  path: `impressum`
}

const Page = ({ data }) => {
  const postNode = {
    title: pageConfig.title,
  }

  const block1 = {
    as: 'header',
    leading: 5,
    variant: VARIANT_TEXT_INTENDED,
    headline: `Impressum`,
    copytext: `
    <p><strong>Ziegler GmbH</strong></p>
    <p>Eisenbahnstraße 17<br/>74831 Gundelsheim</p>
    <p>Telefon: 06269 / 45095<br/>Telefax: 06269 / 45097</p>
    <p>E-Mail: info@raumausstattung-ziegler.de</p>
    <p>Geschäftsführer:<br/>Bernhard Ziegler</p>
    <p>Verantwortlicher i.S.d. § 55 Abs. 2 RStV:<br/>Isabella Eckhardt</p>
    `,
  }

  const block2 = {
    as: 'section',
    leading: 5,
    variant: VARIANT_TEXT_INTENDED,
    headlineSize: 3,
    headline: `Externe Links`,
    copytext: `
    <p>Der Herausgeber distanziert sich von den Inhalten aller externen Links und macht sich diese Inhalte nicht zu Eigen. Für die Inhalte der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Der Herausgeber übernimmt keine Haftung für durch anderen Websites entstandene Schäden. Dies betrifft insbesondere Vertragsklagen, Fahrlässigkeitsklagen oder andere Schadensersatzklagen, die sich aus der Nutzung dieser Links ergeben.</p>
    `,
  }

  const block3 = {
    as: 'section',
    leading: 5,
    variant: VARIANT_TEXT_INTENDED,
    headlineSize: 3,
    headline: `Keine Haftung für Inhalte`,
    copytext: `
    <p>Ziegler-raumgestaltung.de hat keine Kontrolle über die durch die Nutzung von URL verbreiteten Informationen. Daher übernimmt der Herausgeber auch keinerlei Haftung für diese Inhalte. Alle Inhalte, auf die der Benutzer über URL oder Subdomains die zu URL führen, zugreift, werden von diesem auf eigenes Risiko verwendet, insbesondere übernimmt der Herausgeber keine Garantie und macht keine Zusicherungen hinsichtlich der Verwendung oder den Folgen der Verwendung solcher Inhalte. Der Herausgeber lehnt jede Haftung für Forderungen, Verluste, Prozesse, Schadensersatzforderungen, Klagen oder Verfahren, die sich aus der Nutzung dieser Inhalte ergeben oder damit im Zusammenhang stehen, ab.</p>
    `,
  }

  return (
    <Layout>
      <Helmet>
        <title>{pageConfig.title}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath={pageConfig.path} customTitle />

      <div>
        <article>
          <Article {...block1}></Article>
          <Article {...block2}></Article>
          <Article {...block3}></Article>
        </article>
      </div>
    </Layout>
  )
}

export default Page
